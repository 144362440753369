
<div class="register-form">
  <form
    name="form"
    #f="ngForm"
    (ngSubmit)="f.form.valid "
   
  >
    <div class="form-group">
      <label>Site Name</label>
      <mat-form-field appearance="outline">
        <mat-select class="form-control" name="sitename" #sitename="ngModel"  [(ngModel)]="form.siteId" (selectionChange)="selectedSite(sitename.value)" required [ngClass]="{ 'is-invalid': f.submitted && sitename.errors }" >
          <mat-option value="">Select Site</mat-option>
          <mat-option *ngFor="let m of sitesData" [value]="m.siteId">{{ m.siteName }}</mat-option>
       </mat-select >
      </mat-form-field>
     
      <div *ngIf="f.submitted && sitename.errors" class="invalid-feedback">
        <div *ngIf="sitename.errors['required']">Site name is required</div>
      </div>
    </div>
    <div class="form-group">
      <label>Facility Name</label>
      <mat-form-field appearance="outline">
        <mat-select  [disabled]="!form.siteId" class="form-control" name="facilityname" #facilityname="ngModel" (selectionChange)="selectedFacility(facilityname.value)" [(ngModel)]="form.facilityId" required [ngClass]="{ 'is-invalid': f.submitted && facilityname.errors }" >
          <mat-option [value]="">Select Level</mat-option>

          <mat-option *ngFor="let m of facilityData" [value]="m.facilityId">{{ m.facilityName }}</mat-option>
       </mat-select >
      </mat-form-field>
     
      <div *ngIf="f.submitted && facilityname.errors" class="invalid-feedback">
        <div *ngIf="facilityname.errors['required']">facility name is required</div>
      </div>
    </div>
    <div class="form-group">
      <label>Level Name</label>
      <mat-form-field appearance="outline">
        <input matInput
        [disabled]="!form.facilityId"
        type="text"
        class="form-control"
        name="levelname"
        [(ngModel)]="form.levelName"
        required
        #levelname="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && levelname.errors }"
      /> 
      </mat-form-field>
      
      <div *ngIf="f.submitted && levelname.errors" class="invalid-feedback">
        <div *ngIf="levelname.errors['required']">Level name is required</div>
      </div>
    </div>
    <div class="form-group">
      <div class="buttongroup">
        <div class="image-upload">
          <label for="file-input">
           <mat-icon>cloud_upload</mat-icon>
          </label>
          <p class="note"><span style="font-size: 12px;">(Supported File Formats:*/geojson,.png,.jpg,JPEG)</span></p>
          <input [disabled]="!form.levelName"  id="file-input" type="file" class="upload" (change)="fileUpload($event.target.files[0])"   accept=".geojson,.jpg,.img,.png,.JPEG" />
      </div> 
    
      </div>
     
    </div>
   
    <div class="buttongroup"> 
      <button [disabled]="!form.fileUrl" (click)="onSubmit()" type="submit" color="primary" mat-raised-button >Submit</button>
    <button
    mat-raised-button 
      type="button"
      (click)="onReset(f)"
      color="warn"
    >
      Reset
    </button>

    </div>
  </form>
</div>