
<div class="register-form">
  <form
    name="form"
    #f="ngForm"
    (ngSubmit)="f.form.valid"
   
  >
    <div class="form-group">
      <label>Site Name</label>
      <mat-form-field appearance="outline">
        <mat-select class="form-control" name="sitename" #sitename="ngModel" [(ngModel)]="form.siteId" (selectionChange)="selectedSite(sitename.value)" required [ngClass]="{ 'is-invalid': f.submitted && sitename.errors }" >
          <mat-option value="">Select Site</mat-option>
          <mat-option *ngFor="let item of sitesData" [value]="item.siteId">{{ item.siteName }}</mat-option>
      </mat-select >
      
      </mat-form-field>
      <div *ngIf="f.submitted && sitename.errors" class="invalid-feedback">
        <div *ngIf="sitename.errors['required']">Sitename is required</div>
      </div>
    </div>
    <div class="form-group">
      <label>Facility Name</label>

      <mat-form-field appearance="outline">
        <input  matInput
        type="text"
        class="form-control"
        name="facilityname"
        [disabled]="!form.siteId"
        [(ngModel)]="form.facilityName"
        required
        #facilityname="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && facilityname.errors }"
      />
      </mat-form-field>
      
      <div *ngIf="f.submitted && facilityname.errors" class="invalid-feedback">
        <div *ngIf="facilityname.errors['required']">Facility name is required</div>
      </div>
    </div>

    <div class="form-group">
      <div class="buttongroup">
        <div class="image-upload">
          <label for="file-input">
           <mat-icon>cloud_upload</mat-icon>
          </label>
          <p class="note"><span style="font-size: 12px;">(Supported File Formats:*/geojson,.png,.jpg,JPEG)</span></p>
          <input [disabled]="!form.facilityName"  id="file-input" type="file" class="upload" (change)="fileUpload($event.target.files[0])"  accept=".geojson,.jpg,.img,.png,.JPEG" />
      </div> 
      
      
      <a *ngIf="form.imageUrl" (click)="redirect()">click here to locate from map <mat-icon matSuffix>location_on</mat-icon></a>


      <mat-form-field appearance="outline">
        <input  matInput
        [disabled]="!form.facilityName"
        type="text"
        class="form-control"
        name="Location"
        [(ngModel)]="form.lan+','+ form.lat"
        required
        minlength="6"
        maxlength="20"
        #location="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && location.errors }"
      />
    </mat-form-field>
      
      
      <div *ngIf="f.submitted && location.errors" class="invalid-feedback">
        <div *ngIf="location.errors['required']">location is required</div>
        <div *ngIf="location.errors['minlength']">
          location must me geometry
        </div>
        <div *ngIf="location.errors['maxlength']">
          location must me geometry
        </div>
      </div>
      </div>
     
    </div>


    <div class="buttongroup"> 
      <button  type="submit" (click)="onSubmit()" color="primary" mat-raised-button >Submit</button>
    <button
    mat-raised-button 
      type="button"
      (click)="onReset(f)"
      color="warn"
    >
      Reset
    </button>

    </div>
  </form>
</div>