

<div class="register-form">
  <form
    name="form"
    #f="ngForm"   
  >
  <div class="form-group">
    <label>Device Type</label>
    <mat-form-field appearance="outline">
      <mat-select   class="form-control" name="deviceType" #deviceType="ngModel" (selectionChange)="selectedDevice(deviceType.value)" [(ngModel)]="form.deviceType" required [ngClass]="{ 'is-invalid': f.submitted && deviceType.errors }" >
        <mat-option *ngFor="let m of DevicesTypes" [value]="m">{{ m }}</mat-option>
     </mat-select >
    </mat-form-field>
   
    <div *ngIf="f.submitted && deviceType.errors" class="invalid-feedback">
      <div *ngIf="deviceType.errors['required']">facility name is required</div>
    </div>
  </div>
  <div class="form-group">
    <label>Device Id</label>
    <mat-form-field appearance="outline">
      <input matInput
      [disabled]="!form.deviceType"
      type="text"
      class="form-control"
      name="deviceID"
      [(ngModel)]="form.deviceId"
      required
      #deviceID="ngModel"
      [ngClass]="{ 'is-invalid': f.submitted && deviceID.errors }"
    /> 
    </mat-form-field>
    
    <div *ngIf="f.submitted && deviceID.errors" class="invalid-feedback">
      <div *ngIf="deviceID.errors['required']">Level name is required</div>
    </div>
  </div>
    <div class="buttongroup">
      <button [disabled]="!form.deviceId" (click)="onSubmit()" type="submit" color="primary" mat-raised-button >Submit</button>
    <button
    mat-raised-button 
      type="button"
      (click)="onReset(f)"
      color="warn"
    >
      Reset
    </button>

    </div>
  </form>
</div>