import { Component, AfterViewInit, OnInit } from '@angular/core';

@Component({
  selector: 'app-add-location',
  template: `
    <p>
      
    </p>
  `,
  styles: [

  ]
})
export class AddLocationComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
   
  }

}
